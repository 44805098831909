<template>
  <div>
    <a @click="$router.back()" href="#"
      class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg d-flex flex-row align-items-center">
      <i class="mdi mdi-chevron-left mr-2 mt-1"></i>
      Kembali
    </a>
    <validation-observer ref="formVendor">
      <b-form @submit.prevent="submitForm" @reset="onReset">
        <div class="card card-custom card-stretch">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                {{ isEdit ? "Edit Sekolah" : "Buat Sekolah" }}
              </span>
            </h3>
            <div class="card-toolbar">
              <button type="submit" class="btn btn-success font-weight-bolder font-size-sm mr-2">
                Simpan
              </button>
              <span @click="$router.back()" class="btn btn-secondary font-weight-bolder font-size-sm">
                Batal</span>
            </div>
          </div>
          <div class="card-body pt-4">
            <b-form-group id="input-group-1" label="Kode Sekolah:" label-for="input-1">
              <validation-provider #default="{ errors }" name="Kode Sekolah" rules="required">
                <b-form-input :state="errors.length > 0 ? false : null" id="input-1"
                  v-model="formCurrentVendor.body.school_code" type="text"
                  placeholder="Masukkan Kode Sekolah"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group id="input-group-1" label="Nama Sekolah:" label-for="input-1">
              <validation-provider #default="{ errors }" name="Nama Sekolah" rules="required">
                <b-form-input :state="errors.length > 0 ? false : null" id="input-1"
                  v-model="formCurrentVendor.body.name" type="text" placeholder="Masukkan Nama Sekolah"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group id="input-group-1" label="Email Operator Sekolah:" label-for="input-1">
              <validation-provider #default="{ errors }" name="Email" rules="required|email">
                <b-form-input :state="errors.length > 0 ? false : null" id="input-1"
                  v-model="formCurrentVendor.body.email" type="text"
                  placeholder="Masukkan Email Operator Sekolah"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group v-if="!isEdit" id="input-group-1" label="Username:" label-for="input-1">
              <validation-provider #default="{ errors }" name="Username" rules="required">
                <b-form-input :state="errors.length > 0 ? false : null" id="input-1"
                  v-model="formCurrentVendor.body.username" type="text" placeholder="Masukkan Username"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group v-if="!isEdit" id="input-group-1" label="Password:" label-for="input-1">
              <validation-provider #default="{ errors }" name="Password" rules="required">
                <b-form-input :state="errors.length > 0 ? false : null" id="input-1"
                  v-model="formCurrentVendor.body.password" type="password"
                  placeholder="Masukkan Password"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group id="input-group-1" label="Alamat Sekolah:" label-for="input-1">
              <validation-provider #default="{ errors }" name="Alamat" rules="required">
                <b-form-textarea :state="errors.length > 0 ? false : null" id="input-1"
                  v-model="formCurrentVendor.body.address" type="text" rows="3"
                  placeholder="Masukkan Alamat"></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group id="input-group-1" label="Kecamatan:" label-for="input-1">
              <validation-provider #default="{ errors }" name="Kecamatan" rules="required">
                <b-form-input :state="errors.length > 0 ? false : null" id="input-1"
                  v-model="formCurrentVendor.body.districts" type="text" rows="3"
                  placeholder="Masukkan Kecamatan"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group id="input-group-1" label="Titik Koordinat:" label-for="input-1">
              <validation-provider #default="{ errors }" name="Titik Koordinat" rules="required">
                <b-form-input :state="errors.length > 0 ? false : null" id="input-1"
                  v-model="formCurrentVendor.body.coordinates" type="text" rows="3"
                  placeholder="Masukkan Titik Koordinat"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group id="input-group-1" label="Akreditasi:" label-for="input-1">
              <validation-provider #default="{ errors }" name="Akreditasi" rules="required">
                <b-form-input :state="errors.length > 0 ? false : null" id="input-1"
                  v-model="formCurrentVendor.body.accreditation" type="number" rows="3"
                  placeholder="Masukkan Akreditasi"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- <b-form-group
              v-if="!isEdit"
              id="input-group-1"
              label="Username:"
              label-for="input-1"
            >
              <validation-provider
                #default="{ errors }"
                name="Username"
                rules="required"
              >
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  id="input-1"
                  v-model="formCurrentVendor.body.username"
                  type="text"
                  placeholder="Masukkan Username"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-if="!isEdit"
              id="input-group-1"
              label="Password:"
              label-for="input-1"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  id="input-1"
                  v-model="formCurrentVendor.body.password"
                  type="password"
                  placeholder="Masukkan Password"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapState } from "vuex"
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

import { } from "@/core/services/store/banksoal.module"
import Swal from "sweetalert2"

import {
  STORE_VENDOR,
  UPDATE_VENDOR,
} from "@/core/services/store/vendor.module"
import { ValidationObserver, ValidationProvider } from "vee-validate"

export default {
  name: "VendorForm",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      formCurrentVendor: {
        body: {},
      },
      isLoading: false,
      editorOption: {
        // Some Quill options...
      },
      isEdit: false,
    }
  },
  methods: {
    submitForm() {
      this.$refs.formVendor.validate().then(success => {
        if (success) {
          var _data = JSON.parse(JSON.stringify(this.formCurrentVendor))
          // console.log(this.isEdit, _data)
          if (this.isEdit) {
            _data = {
              _id: this.currentVendor._id,
              accreditation: this.formCurrentVendor.body.accreditation,
              address: this.formCurrentVendor.body.address,
              coordinates: this.formCurrentVendor.body.coordinates,
              districts: this.formCurrentVendor.body.districts,
              email: this.formCurrentVendor.body.email,
              name: this.formCurrentVendor.body.name,
              password: this.formCurrentVendor.body.password,
              school_code: this.formCurrentVendor.body.school_code,
              username: this.formCurrentVendor.body.username,
            }
          }
          // console.log(_data)
          this.$store
            .dispatch(this.isEdit ? UPDATE_VENDOR : STORE_VENDOR, _data)
            .then(response => {
              Swal.fire({
                title: response.message,
                text: `Berhasil ${this.isEdit ? "Ubah" : "Buat"} Sekolah`,
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
              })
              this.$router.push(`/vendor`)
              this.isLoading = false
            })
            .catch(() => {
              this.isLoading = false
              this.$bvToast.toast(this.errors, {
                title: `Gagal ${this.isEdit ? "Mengubah" : "Membuat"} Sekolah`,
                variant: "danger",
                solid: true,
              })
            })
        }
      })
    },
    onReset() { },
  },
  mounted() {
    if (this.vendorId) {
      this.formCurrentVendor.body = this.currentVendor
      this.isEdit = true
    }
  },
  computed: {
    ...mapState({
      errors: state => state.vendorModule.errors,
    }),
    ...mapGetters(["currentVendor"]),
    vendorId() {
      return this.$route.params.vendorId
    },
  },
}
</script>
